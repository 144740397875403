//Modelo para obtener información del usuario
export class UsuarioModel{
    constructor(
        public name: string,
        public lastName: string,
        public gender: string,
        public email: string,
        public password: string,
        public companyId: number,
        public roles?: any,
        public id?: number,
    ) {}
  }

  //Modelo para editar usuario
  export class UsuarioEditModel{
    constructor(
        public id: number,
        public name: string,
        public lastName: string,
        public gender: string,
        public email: string,
        public password: string,
        public companyId: number,
        public roles?: any,
    ) {}
  }