<div align="center" style="height: 100%; width: 100%; padding-top: 100px;" >

  <div style="width: 500px; max-height: 420px; min-height: 200px;">
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-md-6">
          <div style="width: 240px; height: 200px; margin-right: 20px;">
            <img class="imagen" class="img-fluid" src="assets/img/INAB.png" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <div style="width: 240px; height: 200px;">
            <img class="imagen" class="img-fluid" src="assets/img/ITTO.GIF" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <div style="width: 500px; height: 200px;">
    <div class="form">
      <form [formGroup]="basicForm" class="login-form">
        <input type="email" class="form-control" name="username" formControlName="username" list="datalistOptions"
          id="exampleDataList" placeholder="Ingrese su usuario" style=" border-color: #7BC031;">
          <div *ngIf="usernameField.errors && usernameField.dirty">
            <p style="color: red; font-size: 12px;" *ngIf="usernameField.hasError('required')">*el campo es inválido</p>
            <p style="color: red; font-size: 12px;" *ngIf="usernameField.hasError('email')">*el campo es inválido</p>
          </div>
        <input type="password" name="password" formControlName="password" class="form-control" id="inputPassword2"
          placeholder="Ingrese su contraseña" style=" border-color: #7BC031;">
          <div *ngIf="passwordField.errors && passwordField.dirty">
            <p style="color: red; font-size: 12px;" *ngIf="passwordField.hasError('required')">*el campo es inválido</p>
          </div>
        <button (click)="loginUsuario()"><b>Iniciar sesion</b></button>
      </form>
    </div>
  </div>
    
  <div style="width: 500px; margin-top: 30px;">
    <button (click)="modal.open(contra)" style="color: #4A7623; font-size: 11px; background-color: #FFF; border: none;" class="message">¿Olvidaste tu contraseña?</button>
  </div>

  <div style="max-width: 500px; min-width: 500px; margin-top: 50px;">
    <p style="color: #4A7623; font-size: 11px;" class="message">
      La realización de esta aplicación Web fue gracias al apoyo financiero de la Organización Internacional 
      de las Maderas Tropicales -OIMT-“ 
      Proyecto: “Implementación de mecanismos para mejorar la trazabilidad en la cadena productiva de los productos 
      forestales de Guatemala” PD 832/16 (M) Rev.1
    </p>
  </div>

</div>


  <ng-template #mensaje let-modal>
    <div class="modal-body row"
      style="background-color: #183400; width: 500px; height: 150px; margin: auto; border-color: #183400;">
      <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
        {{showMessage}}
      </div>
    </div>
  </ng-template> 

  <ng-template #contra let-modal>
    <div class="modal-body row"
      style="background-color: #183400; width: 500px; height: 250px; margin: auto; border-color: #183400;">
      <div style="text-align: center; color: #FFF; font-size: 16px; margin-top: 40px; margin-bottom: 40px;">
        Ingrese su email
      </div>

      <form [formGroup]="recoveryForm" class="login-form">
        <input type="email" class="form-control" name="username" formControlName="username"
        placeholder="Ingrese su email" style=" border-color: #7BC031;">
        <div *ngIf="usernamePField.errors && usernamePField.dirty">
          <p style="color: red; font-size: 12px;" *ngIf="usernamePField.hasError('required')">*el campo es inválido</p>
          <p style="color: red; font-size: 12px;" *ngIf="usernamePField.hasError('email')">*el campo es inválido</p>
        </div>
          <button 
            (click)="recoveryP()" 
            style="height: 40px; color: #FFF; font-size: 16px; background-color: #7BC031; border: none; border-radius: 5px; margin-top: 40px; margin-left: 45%;" 
            class="message">Enviar
        </button>

      </form>

      
    </div>
  </ng-template> 