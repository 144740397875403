<div align="center">
  <button class="btn btn-secondary boton botonDisable" [routerLink]="['/usuarios/register']">
    Usuario nuevo
  </button>
  <button class="btn btn-secondary boton botonEnable">
    Restaurar contraseña
  </button>
</div>

<div class="container padding">

  <!--
  <label for="formGroupExampleInput">Seleccione un usuario</label>
  <select id="inputState" class="form-select input" style="margin-bottom: 50px;" (change)="selectU($event.target.value)">
    <option value="">Seleccione un usuario</option>
    <option *ngFor="let user of usuarios" [value]="user.id">{{user.name + " " + user.lastName}}</option>
  </select>
  -->

  <div class="padding2" style="margin-bottom: 50px;">
    <form [formGroup]="basicForm">
      <label for="formGroupExampleInput">Contraseña nueva</label>
      <input type="password" class="form-control  input" placeholder="Ingrese una contraseña" formControlName="password">
      <label for="formGroupExampleInput">Verificación de contraseña</label>
      <input type="password" class="form-control  input" placeholder="Ingrese su contraseña nuevamente" formControlName="verifyPassword">  
    </form>
 </div>
  <div align="center" style="margin-top: 2%;">
    <button class="btn btn-secondary boton1" (click)="resetPassword()">
      Cambiar contraseña
    </button>
  </div>

  <div align="center" style="margin-top: 2%;">
    <button class="btn btn-danger boton1" style="background-color: red; border-color: red;" (click)="modal.open(deleteuser)">
      Eliminar cuenta
    </button>
  </div>

</div>

<ng-template #mensaje let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 150px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      {{showMessage}}
    </div>
  </div>
</ng-template>

<ng-template #deleteuser let-modal>
  <div class="modal-body row"
    style="background-color: #183400;  width: auto; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¿Desea eliminar la cuenta?
    </div>
    <div align="center" style="margin-top: 20px;">
      <button (click)="modal.dismiss()" class="btn btn-primary boton2" aria-label="close" type="button">
        No
      </button>
    </div>
    <div align="center" style="margin-top: 20px; margin-bottom: 50px;">
      <button (click)="modal.dismiss(); deleteUser()" class="btn btn-primary boton2" aria-label="close" type="button" >
        Sí
      </button>
    </div>
  </div>
</ng-template>