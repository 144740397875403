<div id="contenedor">
    <div id="slices">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Ingrese nombre de estudio" [(ngModel)]="search"
          aria-label="Recipient's username" aria-describedby="button-addon2">
        <button (click)="buscarEstudioNombre(search)" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border-color: #CED4DA;"><i
            class="fas fa-search" style="color:#7BC031;"></i></button>
      </div>

      <div class="row card contenedor" style="height: 55vh;">
        <a class="row" style="color: black; text-decoration: none;" >
          <div class="col-sm-6" align="center" *ngFor="let studie of studies; let i=index" style="padding-bottom: 2%;">
          <div style="display: flex; flex-direction: row; width: 100%;"> 
            <a [routerLink]="['estudiofinalizado/'+ studie.idStudy]" style="color: black; text-decoration: none; width: 95%;">
              <div class="card" *ngIf="!studie.inProgress">
                <div class="card-body" >
                  <div class="" align="end" style="font-size: 11px;">
                    <label *ngIf="!studie.inProgress" class="" style="color: #FFC700;">Finalizado <i
                        class="fas fa-circle" style="margin-right: 25px;"></i></label>
                  </div>
                  <h5 class="card-title" style="font-size: 20px;">{{studie.studyName}}</h5>
                  <p class="card-text" style="font-size: 15px;">Gerente: {{studie.managerName}}</p>
                </div>
              </div>
            </a>
            <a (click)="modal.open(contenido); selectStudy(studie)" style="height: 30px; display: block; border-color: #FFF; background-color: #FFF; font-size: 20px; width: 5%; margin-left: -26px; margin-top: 13px; position: relative;"><i class="fas fa-ellipsis-v"
              style="color:grey"> </i> </a>
          </div>
          </div>
        </a>
      </div>


    </div>
  </div>

  <ng-template #contenido let-modal>
    <div class="modal-body row"
      style="background-color: #183400;  width: auto; margin: auto; border-color: #183400;">
      
      <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
        Seleccione una opción:
      </div>

      <div align="center" style="margin-top: 20px;">
        <button (click)=" modal.dismiss(); modalEditar.open(editar);" class="btn btn-primary boton2" aria-label="close" type="button">
          Editar estudio
        </button>
      </div>

      <div align="center" style="margin-top: 20px; margin-bottom: 50px;">
        <button (click)=" modal.dismiss(); modalEliminar.open(eliminar);" class="btn btn-primary boton2" aria-label="close" type="button">
          Eliminar estudio
        </button>
      </div>

    </div>
</ng-template>

<ng-template #eliminar let-modal>
  <div class="modal-body row"
    style="background-color: #183400;  width: auto; margin: auto; border-color: #183400;">
    
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¿Desea eliminar el estudio?
    </div>

    <div align="center" style="margin-top: 20px;">
      <button (click)="modal.dismiss()" class="btn btn-primary boton2" aria-label="close" type="button">
        No
      </button>
    </div>

    <div align="center" style="margin-top: 20px; margin-bottom: 50px;">
      <button (click)="deleteEstudio()" class="btn btn-primary boton2" aria-label="close" type="button">
        Sí
      </button>
    </div>
    
  </div>
</ng-template>

<ng-template #editar let-modal>
    <div class="modal-body row"
      style="background-color: #7BC031;  width: 850px; margin: auto; margin-left: -30%; border-color: #7BC031; padding-left: 10%;  padding-right: 10%;  padding-top: 0%;">
      <form [formGroup]="studyForm" class="login-form row">
        <div class="form-group col-md-12" style="margin-top: 20px;">
          <h4 style="color: white; text-align: center; font-weight: bold; font-size: 24px;">Datos del estudio</h4>
        </div>
        <div class="form-group col-md-6">
          <label for="formGroupExampleInput">Fecha de inicio</label>
          <input [defaultValue]="selectStudy.initialDate" type="date" class="form-control input" name="initialDate" formControlName="initialDate"
            placeholder="Ingrese la fecha de inicio del estudio">
        </div>
        <div class="form-group col-md-6" style="margin-bottom: 10px;">
          <label for="formGroupExampleInput">Aserradero consultora</label>
          <input type="text" class="form-control input" name="studyName" formControlName="studyName"
            placeholder="Escriba el nombre de la aserradero co...">
            <div *ngIf="studyNameField.errors && studyNameField.dirty">
              <p style="color: red; font-size: 12px;" *ngIf="studyNameField.hasError('required')">*el campo es inválido</p>
            </div>
        </div>
        <div class="form-group col-md-12">
          <label for="formGroupExampleInput">Nombre del responsable</label>
          <input type="text" class="form-control input" placeholder="Ingrese nombre del responsable"
            name="managerName" formControlName="managerName">
          <div *ngIf="managerNameField.errors && managerNameField.dirty">
            <p style="color: red; font-size: 12px;" *ngIf="managerNameField.hasError('required')">*el campo es inválido</p>
          </div>
        </div>
      </form>
      <div align="center" style="margin-top: 50px;">
        <button (click)="actualizarEstudio();" class="btn btn-primary boton3" aria-label="close" type="button">
          Actualizar estudio
        </button>
      </div>
    </div>
</ng-template>

<ng-template #error let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 200px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¡ No se ha podido realizar la acción !
    </div>
    <div style="text-align: center; color: #FFF; font-size: 24px; ">
      Intentalo Nuevamente
    </div>
  </div>
</ng-template>

<ng-template #exito let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 200px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¡ Estudio actualizado
    </div>
    <div style="text-align: center; color: #FFF; font-size: 24px; ">
      correctamente !
    </div>
  </div>
</ng-template>

<ng-template #delete let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 200px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¡ Estudio eliminado
    </div>
    <div style="text-align: center; color: #FFF; font-size: 24px; ">
      correctamente !
    </div>
  </div>
</ng-template>

<ng-template #invalido let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 70px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 22px; margin-top: 10px;">
        ¡ Todos los campos deben ser válidos !
    </div>
  </div>
</ng-template>