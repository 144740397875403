export class LoginModel{
    constructor(
        public email: string,
        public password: string
    ) {}
  }

  export class RecoveryModel{
    constructor(
        public email: string,
    ) {}
  }