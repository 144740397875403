import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../components/login/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private auth: AuthService,
                private router: Router){

  }


  //para llamar el servicio de autenticacion
  canActivate():boolean {
    if(this.auth.authTokenService() ){
      return true;
    } else{
      this.router.navigateByUrl('/login')
    }

  }

}
