import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import swal from 'sweetalert';
import { environment } from '../../../../environments/environment';
import { UsuarioModel, UsuarioEditModel } from '../models/user.model';
import { SpinnerService } from 'src/app/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor( 
    private http: HttpClient,
    public spinerSvc: SpinnerService
    
    ) { }

  //Servicio para nuevo usuario
  nuevoUsuarioService( usuario: UsuarioModel){
    this.spinerSvc.show();
    return this.http.post(`${environment.apiUrl}users`, usuario).pipe(
      map((resp: any) => {
        this.spinerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinerSvc.hide();
        throwError(err)
        if(err.error.message === "Username already exist")
        {
          return "u";
        }
        else{
          return "e";
        }
      }));
  }

  //Servicio para actualizar usuario
  actualizarUsuarioService(usuario: UsuarioEditModel){
    this.spinerSvc.show();
    return this.http.put(`${environment.apiUrl}users/${usuario.id}`, usuario).pipe(
      map((resp: any) => {
        this.spinerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinerSvc.hide();
        throwError(err)
        return "e";
      }));
  }

  deleteUser(idUsuario: number){
    this.spinerSvc.show();
    return this.http.delete(`${environment.apiUrl}users/${idUsuario}`).pipe(
      map((resp: any) => {
        this.spinerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinerSvc.hide();
        throwError(err)
        return "e";
      }));
  }

  getUsuarioService(){
    this.spinerSvc.show();
    return this.http.get(`${environment.apiUrl}users`).pipe(
      map((resp: any) => {
        this.spinerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinerSvc.hide();
        throwError(err)
        return "e";
      }));
  }
}
