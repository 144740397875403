<body>
    <aside>
        <app-sidebar></app-sidebar>
    </aside>
    <div class="contenido">
        
        <div class="card tarjeta" style="border-radius: 10px; height: 90vh;">
            <div style="text-align: end; padding-bottom: 10px; position:static; padding-left: 100px;">
                <button class="btn btn-secondary" style="background-color: #8DC057; border-color: #8DC057; color: white; font-weight: bold;" (click)="salirComponent()">
                    X
                </button>
            </div>
          
            <router-outlet></router-outlet>
        </div>
    </div>
  </body>