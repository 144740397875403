import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { LoginModel } from '../models/login.model';
import { RecoveryModel } from '../models/login.model';
import { stringify } from '@angular/compiler/src/util';
import { throwError } from 'rxjs';
import swal from 'sweetalert';
import {  UsuarioModel } from '../../users/models/user.model';
import { SpinnerService } from 'src/app/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userGlobal: UsuarioModel = new UsuarioModel('', '', '', '', '', 0, '' ) // Usuario Publico
  userToken: string; //creamos variable para almacenar token

  constructor(
    private http: HttpClient,
    private serviceSvc: SpinnerService,
    ) {
    this.leerToken();
  }

  //Metodo para enviar los datos al login
  loginUsuarioService(login: LoginModel) {
    this.serviceSvc.show();
    return this.http.post(`${environment.apiUrl}auth/login`, login).pipe(
      map((resp: any) => {
        this.serviceSvc.hide();
        this.guardarToken(resp.data.accessToken, JSON.stringify( resp.data.user)); //convierto el usuario a texto
        return resp;
      }),
      catchError((err) => {
        this.serviceSvc.hide();
        throwError(err);
        if(err.error.message === "email o password does not match")
        {
          return "u";
        }
        else{
          return "e";
        }
      }));
  }

  recoveryPasswordService(email: RecoveryModel){
    this.serviceSvc.show();
    return this.http.post(`${environment.apiUrl}users/recoverpassword`, email).pipe(
      map((resp: any) => {
        this.serviceSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.serviceSvc.hide();
        throwError(err);
        return "e";
      }));
  }

  //Metodo para almacenar el token el LocalStorage
  private guardarToken(accessToken: string, userConvertidoATexto: string) {
    this.userToken = accessToken;
    localStorage.setItem('token', accessToken);//guarda el token
    localStorage.setItem('user', userConvertidoATexto);//guarda el usuario
  }

  //Metodo para leer el token en el LocalStorage
  leerToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token'); //indicamos que si el token existe que lo valide con la llave
      this.userGlobal = JSON.parse(localStorage.getItem('user')); //indicamos que si el token existe que lo valide con la llave
    } else {
      this.userToken = ''; //sino es un token vacio
    }

    return this.userToken;
  }

  //para autenticar token y proteger rutas
  authTokenService():boolean{
    return this.userToken.length > 2
  }

  //Metodo para cerrar sesión
  logoutService(){
    localStorage.removeItem('token'); //remueve el token
    localStorage.removeItem('user');//remueve el usuario
  }
}
