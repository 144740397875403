import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { map } from 'rxjs/operators';
import { LoginModel } from './models/login.model';
import { RecoveryModel } from './models/login.model';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showMessage = "";

  login: LoginModel = new LoginModel('', '');
  recovery: RecoveryModel = new RecoveryModel('');


  basicForm: FormGroup;
  recoveryForm: FormGroup;

  
  @ViewChild('mensaje', { static: true }) mensaje: TemplateRef<any>;

  constructor(
    private loginService: AuthService, 
    private auth: AuthService, 
    private router: Router,
    private modalService: BsModalService, 
    public modal: NgbModal,

    ) { }

  ngOnInit(): void {
    this.basicForm = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required)
    });

    this.recoveryForm = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  get usernamePField() {
    return this.recoveryForm.get('username');
  }

  get usernameField() {
    return this.basicForm.get('username');
  }

  get passwordField() {
    return this.basicForm.get('password');
  }

  recoveryP(){
    if(this.recoveryForm.valid){
      this.modal.dismissAll();
      this.recovery.email = this.recoveryForm.value.username;
      this.loginService.recoveryPasswordService(this.recovery)
      .subscribe((res) => {
        if(res === "e"){
          this.showMessage = "No se pudo realizar la acción";
          this.modalService.show(this.mensaje);
        }else{
          this.recoveryForm.reset();
          this.showMessage = "Has recibido tu nueva contraseña en tu correo electronico. por favor inicia sesión con tu usuario (correo electronico) y la nueva contraseña.";
          this.modalService.show(this.mensaje);
        }
      })
    }
  }


  loginUsuario(): any {
    if (!this.basicForm.valid) {
      this.showMessage = "Los campos deben ser válidos";
      this.modalService.show(this.mensaje);
    }
    else {
      this.login.email = this.basicForm.value.username; // formbasic.email.value 'ariel@dooply.io'
      this.login.password = this.basicForm.value.password; // '12345678'
      this.loginService.loginUsuarioService(this.login)
        .subscribe((resp) => {
          if(resp === "u"){
            this.basicForm.reset();
            this.showMessage = "Datos inválidos";
            this.modalService.show(this.mensaje);
          }
          else if(resp === "e")
          {
            this.basicForm.reset();
            this.showMessage = "Ha ocurrido un error intentelo nuevamente";
            this.modalService.show(this.mensaje);
          }else{
            if(resp.data.user.state === true){
              this.basicForm.reset();
              this.router.navigate(['/procesos']);
            }else{
              this.basicForm.reset();
              this.showMessage = "Ha ocurrido un error intentelo nuevamente";
              this.modalService.show(this.mensaje);
            }
          }
        });
    }
  }



}
