import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; //para poder usar RouterLink
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentComponent } from './components/content/content.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SidebarComponent, ContentComponent],
  imports: [
    CommonModule,
    RouterModule, //para poder usar RouterLink
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    MatTabsModule,
    MatIconModule
  ],
  exports: [
    SidebarComponent,
    ContentComponent,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    MatTabsModule,
    MatIconModule
  ]
})
export class SharedModule { }
