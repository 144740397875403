export class StudyModel {
    constructor(
      public companyId: string,
      public finalDate: string,
      public idStudy: string,
      public inProgress: string,
      public initialDate: string,
      public managerName: string,
      public state: string,
      public studyCode: string,
      public studyName: string,
      public userId: string
    ) {}
  }

  export class nuevoEstudioModel {
    constructor(
      public studyName: string,
      public managerName: string,
      public initialDate: Date,
      public finalDate: Date,
      public studyCode: string,
      public inProgress: true,
      public state: true,
      public userId: 0,
      public companyId: 0,
      public idStudy: 0,

      
    ) {}
  }

  export class CompanyModel {
    constructor(
      public name: string,
      public description: string,
      public address: string,
      public phone: string,
      public email: string,
      public adminName: string,
      public register: string,
      public cityId: number,
    ) {}
  }

  export class StudyModelRequest {
    constructor(
      public companyId: number,
      public initialDate: string,
      public managerName: string,
      public studyName: string,
    ) {}
  }

  
  export class CountryModel {
    constructor(
      public idState: 0,
      public name: string,
      public available: true,
      public created_at: Date,
      public updated_at: Date,
      public countryId: 0,
    ) {}
  }

  export class CityModel {
    constructor(
      public idCity: 0,
      public name: string,
      public available: true,
      // public created_at: Date,
      // public updated_at: Date,
      public stateId: 0,
    ) {}
  }
        
  export class CountryModelResponse {
    constructor(
      public idState: 0,
      public name: string,
      public available: true,
      public created_at: Date,
      public updated_at: Date,
      public countryId: 0,
      public city: CityModel
    ) {}
  }


  
     