import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },

  {
    path:'',
    component: LoginComponent //en esta ruta agregaremos alguna imagen principal
  },

  {
    path:'',
    component: LayoutComponent, canActivate: [AuthGuard],
    children:[
      {
        path: 'procesos',
        loadChildren:() => import('./components/estudios_p/estudios-p.module').then(m => m.EstudiosPModule)
      },
    ]
  },
  
  {
    path:'',
    component: LayoutComponent,canActivate: [AuthGuard],
    children:[
      {
        path: 'finalizados',
        loadChildren:() => import('./components/estudios_f/estudios-finalizados.module').then(m => m.EstudiosFinalizadosModule)
      }
      
    ]
  },
  {
    path:'',
    component: LayoutComponent,canActivate: [AuthGuard],
    children:[
      {
        path: 'usuarios',
        loadChildren:() => import('./components/users/users.module').then(m => m.UsersModule)
      }
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
