<body>

  <div class="sidebar">

    <div align="center" style="margin-top: 15%;">
      <img src="assets/img/INABblanco.png" alt="" width="200" margin-top="200" style="margin-right: 10px;">
    </div>
    <a routerLinkActive="active" [routerLink]="['/procesos']" style="margin-top: 10%;">Estudios en proceso</a>
    <a routerLinkActive="active" [routerLink]="['/finalizados']">Estudios finalizados</a>
    <a routerLinkActive="active" [routerLink]="['/usuarios']">Usuarios</a>

  </div>

</body>
