import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../login/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor( private auth: AuthService,
                private router: Router) { }

  ngOnInit(): void {
  }

  salirComponent(){
    this.auth.logoutService(); //destruye el token
    this.router.navigateByUrl('/login')//se va al login
  }
}
