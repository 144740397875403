import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert';
import { CompanyModel, StudyModel, StudyModelRequest } from '../models/studies_f.model';
import { SpinnerService } from 'src/app/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class FinalizadosService {

  constructor(
    private http: HttpClient,
    private spinnerSvc: SpinnerService,
    ) { }

  //Obtener los estudios finalizados 
  getStudiesFinalizadosServices(): Observable<any> {
    this.spinnerSvc.show();
    return this.http.get(`${environment.apiUrl}studies`).pipe(
      map((resp: any) => {
        this.spinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinnerSvc.hide();
        throwError(err)
        return 'e';
      }));
  }

  //Obtener los paises
  getCountryServices(): Observable<any> {
    return this.http.get(`${environment.apiUrl}countries/states`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        throwError(err)
        return 'e';
      }));
  }

  //Obtener las ciudades
  getCityServices(idCountry: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}countries/cities/${idCountry}`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        throwError(err)
        return 'e';
      }));
  }

  createCompanyServices(company: CompanyModel){
    this.spinnerSvc.show();
    return this.http.post(`${environment.apiUrl}companies`, company ).pipe(
      map((resp: any) => {
        this.spinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  crearEstudioServices(estudio: StudyModelRequest){
    this.spinnerSvc.show();
    return this.http.post(`${environment.apiUrl}studies`, estudio ).pipe(
      map((resp: any) => {
        this.spinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  updateEstudioServices(estudio: any, idStudy:number){
    this.spinnerSvc.show();
    return this.http.patch(`${environment.apiUrl}studies/${idStudy}`, estudio ).pipe(
      map((resp: any) => {
        this.spinnerSvc.hide();
        return resp;
      }),
      catchError((error) => {       
        this.spinnerSvc.hide();
        throwError(error);
        return 'e';
      }));
  }

  deleteEstudioServices(idStudy:number){
    this.spinnerSvc.show();
    return this.http.delete(`${environment.apiUrl}studies/${idStudy}`).pipe(
      map((resp: any) => {
        this.spinnerSvc.hide();
        return resp;
      }),
      catchError((error) => {     
        this.spinnerSvc.hide();
        throwError(error);
        return 'e';
      }));
  }

  reportsService(idStudy: number){
    this.spinnerSvc.show();
    return this.http.get(`${environment.apiUrl}reports/${idStudy}`).pipe(
      map((resp: any) => {
        this.spinnerSvc.hide();
        return resp;
      }),
      catchError((error) => {
        this.spinnerSvc.hide();
        throwError(error);
        return 'e';
      }));
  }

  buscarEstudioNombre(nombreE: string){
    this.spinnerSvc.show();
    if(nombreE === ''){
      return this.http.get(`${environment.apiUrl}studies`).pipe(
        map((resp: any) => {
          this.spinnerSvc.hide();
          return resp;
        }),
        catchError((err) => {
          this.spinnerSvc.hide();
          throwError(err);
          return "e";
        }));
    }else {
      return this.http.get(`${environment.apiUrl}studies/find?search=${nombreE}`).pipe(
        map((resp: any) => {
          this.spinnerSvc.hide();
          return resp;
        }),
        catchError((err) => {
          this.spinnerSvc.hide();
          throwError(err);
          return "e";
        }));
    }
  }

  getAllWood(idStudy: number){
    this.spinnerSvc.show();
    return this.http.get(`${environment.apiUrl}woods/all/${idStudy}`).pipe(
      map((resp: any) => {
        this.spinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.spinnerSvc.hide();
        throwError(err);
        return "e";
      }));
  }
}
