import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ProcesoService } from './services/proceso.service';
import {
  CityModel,
  CompanyModel,
  CountryModel,
  StudyModel,
  StudyModelRequest,
  finishStudyModel
} from './models/study.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import swal from 'sweetalert';
import { Location } from '@angular/common';
import { CompanyModelResponse } from './models/company.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FinalizadosService } from 'src/app/components/estudios_f/components/finalizados/services/finalizados.service.ts.service';

@Component({
  selector: 'app-proceso',
  templateUrl: './proceso.component.html',
  styleUrls: ['./proceso.component.scss'],
})
export class ProcesoComponent implements OnInit {
  companyForm: FormGroup;
  studyForm: FormGroup;
  studyFormU: FormGroup;
  studies: StudyModel[] = []; //de esta forma volvemos un arreglo la información que traemos
  countries: CountryModel[] = [];
  cities: CityModel[] = [];
  countrySelected = false;
  company: CompanyModel = new CompanyModel('', '', '', '', '', '', '', 0);
  study: StudyModelRequest = new StudyModelRequest(0, '', '', '');
  router: any;
  companies: CompanyModelResponse[] = [] // Declaramos un Array
  search = '';
  idCompany: number;
  showMessage = "";
  studioSelec: StudyModel;
  finalizarStudy: finishStudyModel = new finishStudyModel("", false, 0);

  @ViewChild('mensaje', { static: true }) mensaje: TemplateRef<any>;

  @ViewChild('exito', { static: true }) exito: TemplateRef<any>;
  @ViewChild('error', { static: true }) error: TemplateRef<any>;
  @ViewChild('delete', { static: true }) delete: TemplateRef<any>;
  @ViewChild('invalido', { static: true }) invalido: TemplateRef<any>;

  constructor(
    private finalizadosService: FinalizadosService,
    private proccessService: ProcesoService,
    public modal: NgbModal,
    public modalEliminar:NgbModal,
    public modalFinalizar:NgbModal,
    public modalEditar:NgbModal,
    public modalError:NgbModal,
    public modalExito:NgbModal,
    public modalCreateEmpresa: NgbModal,
    private modalService: BsModalService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.getProccess();
    this.getCountry();
    this.getCompanies();

    this.companyForm = new FormGroup({
      register: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      adminName: new FormControl(null, Validators.required),
      address: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(null, [Validators.email, Validators.required]),
      cityId: new FormControl(null, Validators.required),
      description: new FormControl(''),
    });

    this.studyForm = new FormGroup({
      studyName: new FormControl(null, [Validators.required]),
      managerName: new FormControl(null, [Validators.required]),
      initialDate: new FormControl(null, [Validators.required]),
      companyId: new FormControl(null),
    });

    this.studyFormU = new FormGroup({
      studyName: new FormControl(null, [Validators.required]),
      managerName: new FormControl(null, [Validators.required]),
      initialDate: new FormControl(null, [Validators.required]),
      companyId: new FormControl(null),
    });
  }

  get managerNameFieldU() {
    return this.studyFormU.get('managerName');
  }

  get studyNameFieldU() {
    return this.studyFormU.get('studyName');
  }

  get studyNameField() {
    return this.studyForm.get('studyName');
  }

  get managerNameField() {
    return this.studyForm.get('managerName');
  }

  get initialDateField() {
    return this.studyForm.get('initialDate');
  }

  get registerField() {
    return this.companyForm.get('register');
  }

  get nameField() {
    return this.companyForm.get('name');
  }

  get adminNameField() {
    return this.companyForm.get('adminName');
  }

  get emailField() {
    return this.companyForm.get('email');
  }

  get cityIdField() {
    return this.companyForm.get('cityId');
  }

  selectStudy(studio: StudyModel){
    this.studioSelec = studio;
    this.studyFormU.get("managerName").patchValue(this.studioSelec.managerName);
    this.studyFormU.get("studyName").patchValue(this.studioSelec.studyName);
    const myArr = this.studioSelec.initialDate.split("T");
    this.studyFormU.get("initialDate").patchValue(myArr[0]);
    this.studyFormU.get("companyId").patchValue(this.studioSelec.companyId);
  }

  getProccess(): void {
    this.proccessService.getStudies().subscribe((res: any) => {
      this.studies = [];
      if(res === 'e')
      {
        this.showMessage = "¡ No se han podido obtener los estudios !  ";
        this.modalService.show(this.mensaje);
      }
      else
      {
        res.forEach(element => {
          if(element.inProgress){
            this.studies = ([ ...this.studies, element ])
          }
        });
      }
    });
  }

  resetForm(){
    this.studyForm.reset();
    this.companyForm.reset();
  }

  //metodo paara ver los paises
  getCountry(): void {
    this.proccessService.getCountryServices().subscribe((res: any) => {
      if(res === 'e')
      {
        this.showMessage = "¡ No se han podido obtener los departamentos !  ";
        this.modalService.show(this.mensaje);
      }
      else
      {
        this.countries = res;
      }
    });
  }

  //metodo para ver las ciudades
  getCity(idCountry: number): void {
    this.proccessService.getCityServices(idCountry).subscribe((res: any) => {
      if(res === 'e')
      {
        this.showMessage = "¡ No se han podido obtener los ciudades !  ";
        this.modalService.show(this.mensaje);
      }
      else
      {
        this.cities = res.cities;
      }
    });
  }

  seleccionCompania(idCompany: number): void {
    this.idCompany = idCompany
  }

  registerStudy(): void {
    if(this.idCompany >= 0){
      if (!this.studyForm.valid) {
        this.showMessage = "¡ Debe ingresar todos los campos ! ";
        this.modalService.show(this.mensaje);
      } else {
        this.studyForm.get("companyId").patchValue( parseInt(this.idCompany.toString()));
        this.proccessService.crearEstudioServices(this.studyForm.value).subscribe((res) => {
          this.studyForm.reset();
          if(res === 'e')
          {
            this.showMessage = "¡ No se ha podido crear el estudio !  ";
            this.modalService.show(this.mensaje);
          }
          else
          {
            this.showMessage = "¡ Estudio creado correctamente !";
            this.modalService.show(this.mensaje);
            this.getProccess();
          }
          this.modal.dismissAll();
        });
      }
    }
    else {
      this.showMessage = "¡ Debe seleccionar una empresa ! ";
      this.modalService.show(this.mensaje);
    }
  }

  registerEmpresa(): void{
    if(this.companyForm.valid){

      if(this.companyForm.value['address'] === null)
      {
        this.companyForm.value['address'] = ""
      }

      if(this.companyForm.value['phone'] === null)
      {
        this.companyForm.value['phone'] = ""
      }

      if(this.companyForm.value['description'] === null)
      {
        this.companyForm.value['description'] = ""
      }

      this.proccessService.createCompanyServices(this.companyForm.value).subscribe((res) => {
        this.companyForm.reset();
        if(res === 'e')
        {
          this.showMessage = "¡ No se ha podido crear la empresa !  ";
          this.modalService.show(this.mensaje);
        }
        else
        {
          this.showMessage = "¡ Empresa creada correctamente !";
          this.modalService.show(this.mensaje);
          this.getCompanies();
        }
      });
      this.modal.dismissAll();
    }
    else{
      this.showMessage = "¡ Ingrese todos los campos !";
      this.modalService.show(this.mensaje);
    }
  }

  getCompanies():void {
    function SortArray(x, y){
      if (x.name < y.name) {return -1;}
      if (x.name > y.name) {return 1;}
      return 0;
    }

    this.proccessService.getCompanies().subscribe((res:any) => {
      if(res === 'e')
      {
        this.showMessage = "¡ No se han podido obtener las empresas registradas !  ";
        this.modalService.show(this.mensaje);
      }
      else
      {
        this.companies = res;
        this.companies = res.sort(SortArray);
      }
    });
  }

  buscarEstudioNombre(nombre: string){
    this.proccessService.buscarEstudioNombre(nombre).subscribe((res: any) => {
      this.studies = [];
      if(res === 'e')
      {
        this.showMessage = "¡ No se han podido obtener los estudios !  ";
        this.modalService.show(this.mensaje);
      }
      else
      {
        res.forEach(element => {
          if(element.inProgress){
            this.studies = ([ ...this.studies, element ])
          }
        });
      }
    })
  }

  actualizarEstudio(){
    if(this.studyFormU.valid){
      this.modalEditar.dismissAll();
      this.finalizadosService.updateEstudioServices(this.studyFormU.value, parseInt(this.studioSelec.idStudy))
      .subscribe((res: any) => {
          if(res === 'e')
          {
            this.modalService.show(this.error);
          }
          else
          {
            this.getProccess();
            this.modalService.show(this.exito);
          }
        }
      );
    }
    else{
      this.modalService.show(this.invalido);
    }
  }

  finalizarEstudio(){
    this.modalFinalizar.dismissAll();
    var fecha = new Date();
    this.finalizarStudy.finalDate = fecha.toISOString();
    this.finalizarStudy.studyId = parseInt(this.studioSelec.idStudy);
    this.proccessService.finalizarEstudioServices(this.finalizarStudy)
    .subscribe((res) => {
      if(res === 'e')
      {
        this.modalService.show(this.error);
      }
      else
      {
        this.getProccess();
        this.showMessage = "¡ Estudio finalizado !";
        this.modalService.show(this.mensaje);
      }
    })
  }

  deleteEstudio(){
    this.modalEliminar.dismissAll();
    this.finalizadosService.deleteEstudioServices(parseInt(this.studioSelec.idStudy))
    .subscribe((res: any) => {
      if(res === 'e')
      {
        this.modalService.show(this.error);
      }
      else
      {
        this.getProccess();
        this.modalService.show(this.delete);
      }
    });
  }
}
