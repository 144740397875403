import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FinalizadosService } from './services/finalizados.service.ts.service';
import { CityModel, CompanyModel, CountryModel, StudyModel, StudyModelRequest } from './models/studies_f.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import swal from 'sweetalert';
import { CompanyModelResponse } from 'src/app/components/estudios_p/components/proceso/models/company.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-finalizados',
  templateUrl: './finalizados.component.html',
  styleUrls: ['./finalizados.component.scss']
})
export class FinalizadosComponent implements OnInit {


  companyForm: FormGroup;
  studyForm: FormGroup;
  studies: StudyModel[] = [] //de esta forma volvemos un arreglo la información que traemos
  countries: CountryModel[] =[]
  cities: CityModel[]=[]
  countrySelected = false;

  company: CompanyModel = new CompanyModel('', '','','','','','', 0)
  study: StudyModelRequest = new StudyModelRequest(0,'','','')
  companies: CompanyModelResponse[] = [] // Declaramos un Array

  studioSelec: StudyModel;

  search = '';

  @ViewChild('exito', { static: true }) exito: TemplateRef<any>;
  @ViewChild('error', { static: true }) error: TemplateRef<any>;
  @ViewChild('delete', { static: true }) delete: TemplateRef<any>;
  @ViewChild('invalido', { static: true }) invalido: TemplateRef<any>;

  constructor(
    private finalizadosService: FinalizadosService,
    public modal:NgbModal,
    public modalEliminar:NgbModal,
    public modalEditar:NgbModal,
    public modalError:NgbModal,
    public modalExito:NgbModal,
    private modalService: BsModalService,
    ) { }

  ngOnInit(): void {
    this.companyForm = new FormGroup({
      register: new FormControl(null),
      name: new FormControl(null),
      adminName: new FormControl(null),
      description: new FormControl(null),
      address: new FormControl(null),
      phone: new FormControl(null),
      email: new FormControl(null),
      state: new FormControl(null),
      city: new FormControl(null),
    });

    this.studyForm = new FormGroup({
      studyName: new FormControl(null, Validators.required),
      managerName: new FormControl(null, Validators.required),
      initialDate: new FormControl(null, Validators.required),
      companyId: new FormControl(null, Validators.required),
    });

    this.getProccess();
    this.getCountry();

  }

  selectStudy(studio: StudyModel){
    this.studioSelec = studio;
    this.studyForm.get("managerName").patchValue(this.studioSelec.managerName);
    this.studyForm.get("studyName").patchValue(this.studioSelec.studyName);
    const myArr = this.studioSelec.initialDate.split("T");
    this.studyForm.get("initialDate").patchValue(myArr[0]);
    this.studyForm.get("companyId").patchValue(this.studioSelec.companyId);
  }

  get managerNameField() {
    return this.studyForm.get('managerName');
  }

  get studyNameField() {
    return this.studyForm.get('studyName');
  }

  //obtener los estudios finalizados
  getProccess() : void {
    this.studies = [];
    this.finalizadosService.getStudiesFinalizadosServices().subscribe((res: any) => {
      if(res === 'e')
      {
        this.modalService.show(this.error);
      }
      else
      {
        res.forEach(element => {
          if(!element.inProgress){
            this.studies = ([ ...this.studies, element ])
          }
        });
      }
    });
  }

  buscarEstudioNombre(nombre: string){
    this.finalizadosService.buscarEstudioNombre(nombre).subscribe((res: any) => {
      if(res === 'e')
      {
        this.modalService.show(this.error);
      }
      else
      {
        this.studies = [];
        res.forEach(element => {
          if(!element.inProgress){
            this.studies = ([ ...this.studies, element ])
          }
        });
      }
    })
  }

  //metodo paara ver los paises
  getCountry() : void {
    this.finalizadosService.getCountryServices().subscribe((res: any) => {
      if(res === 'e')
      {
        this.modalService.show(this.error);
      }
      else
      {
        this.countries = res;
      }
    });
  }

  //metodo para ver las ciudades
  getCity(idCountry: number) : void {
    this.finalizadosService.getCityServices(idCountry).subscribe((res: any) => {
      this.cities = res.cities;
    });
  }

  seleccionCompania(idCompany: number): void {
    this.study.companyId = idCompany;
  }

  actualizarEstudio(){
    if(this.studyForm.valid){
      this.modalEditar.dismissAll();
      this.finalizadosService.updateEstudioServices(this.studyForm.value, parseInt(this.studioSelec.idStudy))
      .subscribe((res: any) => {
          if(res === 'e')
          {
            this.modalService.show(this.error);
          }
          else
          {
            this.getProccess();
            this.modalService.show(this.exito);
          }
        }
      );
    }
    else{
      this.modalService.show(this.invalido);
    }
  }

  deleteEstudio(){
    this.modalEliminar.dismissAll();
    this.finalizadosService.deleteEstudioServices(parseInt(this.studioSelec.idStudy))
    .subscribe((res: any) => {
      if(res === 'e')
      {
        this.modalService.show(this.error);
      }
      else
      {
        this.getProccess();
        this.modalService.show(this.delete);
      }
    });
  }
}
