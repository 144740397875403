<div id="contenedor">

    <div id="slices">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Ingrese nombre de estudio" [(ngModel)]="search"
          aria-label="Recipient's username" aria-describedby="button-addon2">
        <button (click)="buscarEstudioNombre(search)" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border-color: #CED4DA;"><i
            class="fas fa-search" style="color:#7BC031;"></i></button>
      </div>

      <div class="row card contenedor" style="height: 55vh;">
        <a class="row" style="color: black; text-decoration: none;" >
          <div class="col-sm-6" align="center" *ngFor="let studie of studies; let i=index" style="padding-bottom: 2%;">
          <div style="display: flex; flex-direction: row; width: 100%;"> 
            <a [routerLink]="['/procesos/calcular/'+ studie.idStudy]" style="color: black; text-decoration: none; width: 95%;">
              <div class="card" *ngIf="studie.inProgress">
                <div class="card-body" style="text-align: center;">
                  <div [align]="end" style="font-size: 11px; text-align: right;">
                    <label *ngIf="studie.inProgress" class="" style="color: #7BC031;">En progreso <i
                        class="fas fa-circle" style="margin-right: 25px;"></i></label>
                  </div>
                  <h5 class="card-title" style="font-size: 20px;">{{studie.studyName}}</h5>
                  <span class="card-text" style="font-size: 15px;">Gerente: {{studie.managerName}}</span>
                </div>
              </div>
            </a>
            <a (click)="modal.open(contenidoOp); selectStudy(studie)" style="height: 30px; display: block; border-color: #FFF; background-color: #FFF; font-size: 20px; width: 5%; margin-left: -26px; margin-top: 13px; position: relative;"><i class="fas fa-ellipsis-v"
              style="color:grey"> </i> </a>
          </div>
          </div>
        </a>
      </div>
    </div>

    <div id="footer" align="center">
      <div>
        <button type="button" class="btn btn-primary btn-lg btn-block boton" (click)="modal.open(contenido); resetForm();">Iniciar
          estudio nuevo</button>
        <hr style="color:#4A7623;">
      </div>
    </div>
  </div>

  <ng-template #contenido let-modal>
    <fieldset>
      <div class="modal-header"
        style="background-color: #7BC031;  width: 850px; margin: auto; margin-left: -30%; border-color: #7BC031; padding-top: 5%;">
        <!-- <h4 style="color: white;">Datos del estudio</h4> -->
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()"
          style="background-color: #7BC031; border-color:#7BC031;">
          <span aria-hidden="true" style="background-color: #7BC031;">&times;</span>
        </button>
      </div>
      <div class="modal-body row"
        style="background-color: #7BC031;  width: 850px; margin: auto; margin-left: -30%; border-color: #7BC031; padding-left: 10%;  padding-right: 10%;  padding-top: 0%;">
        <form [formGroup]="studyForm" class="login-form row">
          <div class="form-group col-md-12" align="center">
            <label style="font-size: 24px; font-weight:bold; color: white;">Datos de la empresa</label>
          </div>
          <div class="form-group col-md-12" style="width: 100%; display: flex; flex-wrap: wrap; flex-direction: row;">
            <div style="width: 80%;">
              <select id="inputState" class="form-control input" (change)="seleccionCompania($event.target.value)">
                <option>Seleccione una empresa</option>
                <option *ngFor="let company of companies" [value]="company.idCompany">{{company.name}}</option>
              </select>
            </div>
            <div style="width: 20%;">
              <button 
                (click)="modal.dismiss(); modalCreateEmpresa.open(createEmpresa); "
                type="button" 
                style="border: none; width: 100%; height: 38px; border-radius: 10px; background-color: #23763F; color: #FFF;">
                Nueva empresa
              </button>
            </div>
          </div>
          <div class="form-group col-md-12" style="margin-top: 20px;">
            <h4 style="color: white; text-align: center; font-weight: bold; font-size: 24px;">Datos del estudio</h4>
          </div>
          <div class="form-group col-md-6">
            <label for="formGroupExampleInput">Fecha de inicio</label>
            <input type="date" class="form-control input" name="initialDate" formControlName="initialDate"
              placeholder="Ingrese la fecha de inicio del estudio">
              <div *ngIf="initialDateField.errors && initialDateField.dirty">
                <p style="color: red; font-size: 12px;" *ngIf="initialDateField.hasError('required')">*el campo es inválido</p>
              </div>
          </div>
          <div class="form-group col-md-6" style="margin-bottom: 10px;">
            <label for="formGroupExampleInput">Aserradero consultora</label>
            <input type="text" class="form-control input" name="studyName" formControlName="studyName"
              placeholder="Escriba el nombre de la aserradero co...">
              <div *ngIf="studyNameField.errors && studyNameField.dirty">
                <p style="color: red; font-size: 12px;" *ngIf="studyNameField.hasError('required')">*el campo es inválido</p>
              </div>
          </div>
          <div class="form-group col-md-12">
            <label for="formGroupExampleInput">Nombre del responsable</label>
            <input type="text" class="form-control input" placeholder="Ingrese nombre del responsable"
              name="managerName" formControlName="managerName">
              <div *ngIf="managerNameField.errors && managerNameField.dirty">
                <p style="color: red; font-size: 12px;" *ngIf="managerNameField.hasError('required')">*el campo es inválido</p>
              </div>
          </div>
        </form>
        <div align="center" style="margin-top: 50px;">
          <button (click)="registerStudy()" class="btn btn-primary boton2" aria-label="close" type="button">
            Crear estudio
          </button>
        </div>
      </div>
    </fieldset>
  </ng-template>

  <ng-template #createEmpresa let-modal>
    <fieldset>
      <div class="modal-header"
        style="background-color: #7BC031;  width: 850px; margin: auto; margin-left: -30%; border-color: #7BC031; padding-top: 5%;">
        <!-- <h4 style="color: white;">Datos del estudio</h4> -->
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()"
          style="background-color: #7BC031; border-color:#7BC031;">
          <span aria-hidden="true" style="background-color: #7BC031;">&times;</span>
        </button>
      </div>
      <div class="modal-body row"
        style="background-color: #7BC031;  width: 850px; margin: auto; margin-left: -30%; border-color: #7BC031; padding-left: 10%;  padding-right: 10%;  padding-top: 0%;">
        
        <form [formGroup]="companyForm" class="login-form row" style="margin-top: 10px;">

          <div class="form-group col-md-6">
            <label for="formGroupExampleInput" style="font-weight: bold;">*Nombre de la empresa</label>
            <input type="text" class="form-control input" name="name" formControlName="name"
              placeholder="Escriba el nombre de la empresa">
              <div *ngIf="nameField.errors && nameField.dirty">
                <p style="color: red; font-size: 12px;" *ngIf="nameField.hasError('required')">*el campo es inválido</p>
              </div>
          </div>

          <div class="form-group col-md-6">
            <label for="formGroupExampleInput" style="font-weight: bold;">*Número de registro (RNF)</label>
            <input type="text" class="form-control input" name="register" formControlName="register"
              placeholder="Escriba el número de registro">
              <div *ngIf="registerField.errors && registerField.dirty">
                <p style="color: red; font-size: 12px;" *ngIf="registerField.hasError('required')">*el campo es inválido</p>
              </div>
          </div>


          <div class="form-group col-md-6">
            <label for="formGroupExampleInput" style="font-weight: bold;">*Nombre del administador / encargado</label>
            <input type="text" class="form-control input" name="adminName" formControlName="adminName"
              placeholder="Escriba el nombre del administrador">
              <div *ngIf="adminNameField.errors && adminNameField.dirty">
                <p style="color: red; font-size: 12px;" *ngIf="adminNameField.hasError('required')">*el campo es inválido</p>
              </div>
          </div>

          <div class="form-group col-md-6">
            <label for="formGroupExampleInput">Número de telefono</label>
            <input type="text" class="form-control input" name="phone" formControlName="phone"
              placeholder="Número de telefono del responsable">
          </div>

          <div class="form-group col-md-6">
            <label for="formGroupExampleInput" style="font-weight: bold;">*Correo electronico</label>
            <input type="text" class="form-control input" name="email" formControlName="email" 
              placeholder="Ingrese un correo electronico">
              <div *ngIf="emailField.errors && emailField.dirty">
                <p style="color: red; font-size: 12px;" *ngIf="emailField.hasError('required')">*el campo es inválido</p>
                <p style="color: red; font-size: 12px;" *ngIf="emailField.hasError('email')">*Ingrese un correo válido</p>
              </div>
          </div>

          <div class="form-group col-md-6">
            <label for="formGroupExampleInput">Dirección</label>
            <input type="text" class="form-control input" name="address" formControlName="address"
              placeholder="Ingrese una dirección">
          </div>

          <div class="form-group col-md-6">
            <label for="inputStatel" style="font-weight: bold;">*Departamento</label>
            <select id="inputStatel" class="form-control input" (change)="getCity($event.target.value)">
              <option *ngIf="!countrySelected">Seleccione un Departamento</option>
              <option *ngFor="let country of countries" [value]="country.idState">{{country.name}}</option>
            </select>
          </div>

          <div class="form-group col-md-6">
            <label for="inputState" style="font-weight: bold;">*Municipio/Ciudad</label>
            <select id="inputState" class="form-control input" name="cityId" formControlName="cityId">
              <option *ngIf="!countrySelected">Seleccione un Municipio</option>
              <option *ngFor="let city of cities" [value]="city.idCity">{{city.name}}</option>
            </select>
            <div *ngIf="cityIdField.errors && cityIdField.dirty">
              <p style="color: red; font-size: 12px;" *ngIf="cityIdField.hasError('required')">*el campo es inválido</p>
            </div>
          </div>

          <div align="center" style="margin-top: 50px;">
            <button (click)="registerEmpresa()" class="btn btn-primary boton2" aria-label="close" type="button">
              Crear empresa
            </button>
          </div>
        </form>
      </div>
    </fieldset>
  </ng-template>

  <ng-template #mensaje let-modal>
    <div class="modal-body row"
      style="background-color: #183400; width: 500px; height: 150px; margin: auto; border-color: #183400;">
      <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
        {{showMessage}}
      </div>
    </div>
  </ng-template>

  <ng-template #contenidoOp let-modal>
    <div class="modal-body row"
      style="background-color: #183400;  width: auto; margin: auto; border-color: #183400;">
      
      <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
        Seleccione una opción:
      </div>

      <div align="center" style="margin-top: 20px;">
        <button (click)=" modal.dismiss(); modalEditar.open(editar);" class="btn btn-primary boton2" aria-label="close" type="button">
          Editar estudio
        </button>
      </div>

      <div align="center" style="margin-top: 20px;">
        <button (click)=" modal.dismiss(); modalFinalizar.open(finalizar);" class="btn btn-primary boton2" aria-label="close" type="button">
          Finalizar estudio
        </button>
      </div>

      <div align="center" style="margin-top: 20px; margin-bottom: 50px;">
        <button (click)=" modal.dismiss(); modalEliminar.open(eliminar);" class="btn btn-primary boton2" aria-label="close" type="button">
          Eliminar estudio
        </button>
      </div>

    </div>
</ng-template>

<ng-template #eliminar let-modal>
  <div class="modal-body row"
    style="background-color: #183400;  width: auto; margin: auto; border-color: #183400;">
    
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¿Desea eliminar el estudio?
    </div>

    <div align="center" style="margin-top: 20px;">
      <button (click)="modal.dismiss()" class="btn btn-primary boton2" aria-label="close" type="button">
        No
      </button>
    </div>

    <div align="center" style="margin-top: 20px; margin-bottom: 50px;">
      <button (click)="deleteEstudio()" class="btn btn-primary boton2" aria-label="close" type="button">
        Sí
      </button>
    </div>
    
  </div>
</ng-template>

<ng-template #finalizar let-modal>
  <div class="modal-body row"
    style="background-color: #183400;  width: auto; margin: auto; border-color: #183400;">
    
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¿Desea finalizar el estudio?
    </div>

    <div align="center" style="margin-top: 20px;">
      <button (click)="modal.dismiss()" class="btn btn-primary boton2" aria-label="close" type="button">
        No
      </button>
    </div>

    <div align="center" style="margin-top: 20px; margin-bottom: 50px;">
      <button (click)="finalizarEstudio()" class="btn btn-primary boton2" aria-label="close" type="button">
        Sí
      </button>
    </div>
    
  </div>
</ng-template>

<ng-template #editar let-modal>
  <div class="modal-body row"
    style="background-color: #7BC031;  width: 850px; margin: auto; margin-left: -30%; border-color: #7BC031; padding-left: 10%;  padding-right: 10%;  padding-top: 0%;">
    <form [formGroup]="studyFormU" class="login-form row">
      <div class="form-group col-md-12" style="margin-top: 20px;">
        <h4 style="color: white; text-align: center; font-weight: bold; font-size: 24px;">Datos del estudio</h4>
      </div>
      <div class="form-group col-md-6">
        <label for="formGroupExampleInput">Fecha de inicio</label>
        <input [defaultValue]="selectStudy.initialDate" type="date" class="form-control input" name="initialDate" formControlName="initialDate"
          placeholder="Ingrese la fecha de inicio del estudio">
      </div>
      <div class="form-group col-md-6" style="margin-bottom: 10px;">
        <label for="formGroupExampleInput">Aserradero consultora</label>
        <input type="text" class="form-control input" name="studyName" formControlName="studyName"
          placeholder="Escriba el nombre de la aserradero co...">
          <div *ngIf="studyNameFieldU.errors && studyNameFieldU.dirty">
            <p style="color: red; font-size: 12px;" *ngIf="studyNameFieldU.hasError('required')">*el campo es inválido</p>
          </div>
      </div>
      <div class="form-group col-md-12">
        <label for="formGroupExampleInput">Nombre del responsable</label>
        <input type="text" class="form-control input" placeholder="Ingrese nombre del responsable"
          name="managerName" formControlName="managerName">
        <div *ngIf="managerNameFieldU.errors && managerNameFieldU.dirty">
          <p style="color: red; font-size: 12px;" *ngIf="managerNameFieldU.hasError('required')">*el campo es inválido</p>
        </div>
      </div>
    </form>
    <div align="center" style="margin-top: 50px;">
      <button (click)="actualizarEstudio();" class="btn btn-primary boton3" aria-label="close" type="button">
        Actualizar estudio
      </button>
    </div>
  </div>
</ng-template>

<ng-template #delete let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 200px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¡ Estudio eliminado
    </div>
    <div style="text-align: center; color: #FFF; font-size: 24px; ">
      correctamente !
    </div>
  </div>
</ng-template>

<ng-template #error let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 200px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¡ No se ha podido realizar la acción !
    </div>
    <div style="text-align: center; color: #FFF; font-size: 24px; ">
      Intentalo Nuevamente
    </div>
  </div>
</ng-template>

<ng-template #exito let-modal>
  <div class="modal-body row"
    style="background-color: #183400; width: 500px; height: 200px; margin: auto; border-color: #183400;">
    <div style="text-align: center; color: #FFF; font-size: 24px; margin-top: 40px;">
      ¡ Estudio actualizado
    </div>
    <div style="text-align: center; color: #FFF; font-size: 24px; ">
      correctamente !
    </div>
  </div>
</ng-template>