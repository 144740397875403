import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// para poder hacer consultas http
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//Importar rutas
import { LoginComponent } from './components/login/login.component';
// import { SidebarComponent } from './components/shared/components/sidebar/sidebar.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ProcesoComponent } from './components/estudios_p/components/proceso/proceso.component';
import { SharedModule } from './components/shared/shared.module';
import { FinalizadosComponent } from './components/estudios_f/components/finalizados/finalizados.component';
import { InterceptorService } from './services/interceptor.service';
import { ResetPasswordComponent } from './components/users/components/reset-password/reset-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
//import { ChartsModule } from 'ng2-charts/ng2-charts';
import { SpinnerModule } from './components/shared/components/spinner/spinner.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // SidebarComponent,
    LayoutComponent,
    ProcesoComponent,
    FinalizadosComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    SpinnerModule,
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
