import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/components/login/services/auth.service';
import swal from 'sweetalert';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioEditModel, UsuarioModel } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  usuario: UsuarioModel = new UsuarioModel('', '', '', '', '', 0, '');
  usuarioEditar: UsuarioEditModel = new UsuarioEditModel(0, '', '', '', '', '', 0, ''); //creamos variable y la igualamos con el modelo usuarioEditModel

  user: UsuarioModel;

  basicForm: FormGroup;
  showMessage = "";

  usuarios: UsuarioModel[] = []; // Declaramos un Array
  @ViewChild('mensaje', { static: true }) mensaje: TemplateRef<any>;
  @ViewChild('delete', { static: true }) delete: TemplateRef<any>;

  usuarioCambio: UsuarioModel;

  constructor(private authService: AuthService,
    private userService: UserService,
    private modalService: BsModalService,
    public modal: NgbModal,
    private auth: AuthService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.getUsers();
    this.usuario = this.authService.userGlobal; //usuario es igual user del authservice
    this.basicForm = new FormGroup({
      password: new FormControl(null, Validators.required),
      verifyPassword: new FormControl(null, Validators.required),
    });
  }

  selectU(idUsuario){
    if(idUsuario !== ""){
      this.user = this.usuarios.find(item => item.id === parseInt(idUsuario));
    }
  }


  getUsers(): void{
    function SortArray(x, y){
      if (x.name < y.name) {return -1;}
      if (x.name > y.name) {return 1;}
      return 0;
    }

    this.userService.getUsuarioService()
    .subscribe((res) => {
      if(res === "e"){
        this.showMessage = "¡ Error al obtener los usuarios !";
        this.modalService.show(this.mensaje);
      }else{
        this.usuarios = res.sort(SortArray);
      }
    })
  }

  //Metodo para resetaer contraseña
  resetPassword() {
    if (!this.basicForm.valid) {
      this.showMessage = "¡ Los campos deben ser válidos !";
      this.modalService.show(this.mensaje);
    } else {
      if (this.camposIguales(this.basicForm.value.password, this.basicForm.value.verifyPassword)) {
        this.usuarioEditar.id = this.usuario.id;

        //todos los campos
        this.usuarioEditar.name = this.usuario.name;
        this.usuarioEditar.lastName = this.usuario.lastName;
        this.usuarioEditar.gender = this.usuario.gender;
        this.usuarioEditar.email = this.usuario.email;
        this.usuarioEditar.password = this.basicForm.value.password;
        this.usuarioEditar.roles = this.usuario.roles;
        this.usuarioEditar.companyId = this.usuario.companyId;

        this.userService.actualizarUsuarioService(this.usuarioEditar)
        .subscribe((res) => {
          if(res === "e"){
            this.showMessage = "¡ Error no se puede realizar la acción !";
            this.modalService.show(this.mensaje);
          }else{
            this.showMessage = "¡ Contraseña actualizada satisfactoriamente !";
            this.modalService.show(this.mensaje);
          }
          this.basicForm.reset();
        })
      }
      else {
        this.showMessage = "¡ Las contraseñas deben ser iguales !";
        this.modalService.show(this.mensaje);
      }
    }
  }

  deleteUser(){
    this.userService.deleteUser(this.usuario.id)
    .subscribe((res) => {
      if(res === "e"){
        this.showMessage = "¡ Error no se puede realizar la acción !";
        this.modalService.show(this.mensaje);
      }else{
        this.auth.logoutService(); //destruye el token
        this.router.navigateByUrl('/login')//se va al login
      }
    })

  }

  //Metodo campos iguales es para validar contraseña
  camposIguales(palabra1: string, palabra2: string): boolean {
    if (palabra1 === palabra2) {
      return true;
    } else { return false }
  }

}
